@import '../../../styles/common';

.fieldset {
  border: none;
  margin-top: 24px;
  padding-left: 0;
  padding-right: 0;

  legend {
  }

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
  }
}
