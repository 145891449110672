@import '../../styles/common';

.block {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkBoxContainer {
  margin-left: 25px;
}
