@import '../../../styles/vars';
@import '../../../styles/common';

.alignRight {
  margin-top: $primaryPadding;
}

.resultsForm {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  li:last-child {
    text-align: right;
  }
  li.liSecond {
    display: block;
    position: relative;
    margin: 0 !important;

    > div {
      position: absolute;
      top: -55px;
      left: 220px;
    }
  }
}

.estimatesTable {
  max-height: $dialogMaxHeight - 2 * $primaryPadding - $titleHeight -
    $buttonsPanelHeight;

  select {
    width: 175px;
    height: 70px;
    white-space: break-spaces;
  }
}

.hidden {
  display: none !important;
}

.fine {
  color: $red;
}

.marginLeft {
  margin-left: 25px;
}

.selectMargin {
  margin-bottom: 16px;
}
