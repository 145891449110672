@import '../../../styles/common';
@import '../../../styles/colors';
@import '../../../styles/common.module';

.container {
  padding: 0;
  @media (max-width: 800px) {
    button {
      font-size: $largeSizeText;
    }
  }

  table {
    .last {
      color: $black;
    }
    .now {
      color: $selectRed;
    }
    .future {
      color: $inputGreen;
    }
  }

  .tableTop {
    margin-bottom: $primaryPadding;
    min-height: 92px;
    max-height: 100%;
  }
}

.fine {
  color: $red;
}

.addBtn {
  vertical-align: middle;
}

.highlited {
  background: $lightGray;
}

.selected {
}
