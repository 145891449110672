@import './lightTheme.scss';

$inputHeight: 35px;
$headerHeight: 47px;
$topPanelHeight: 52px;

$tabsWidth: 180px;
$tabsWidthShort: 68px;
$titleHeight: 50px;
$buttonsPanelHeight: 80px;
$primaryPadding: 20px;
$semiBold: 600;
$dialogMaxHeight: 660px;
$tabsTitleHeight: 50px;

$largeSizeText: 14px;
$normalSizeText: 12px;

$lightTheme: nvrt-light_theme;
$darkTheme: nvrt-dark_theme;

:export {
  lightTheme: $lightTheme;
  darkTheme: $darkTheme;
}
