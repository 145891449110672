@import 'colors';
@import 'vars';

.warning {
  background: $warning-background-color;
  color: $primary-font-color;
}

.success {
  background: $success-background-color;
  color: $primary-font-color;
}

.selected {
  background: $highlight-background-color;
  color: $secondary-font-color;
}

.headerSelect {
  width: 100%;
  font-size: 14px;
  padding: 0;
}

.headerCentered {
  text-align: center;
}
