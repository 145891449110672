.statusTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .marginLeft {
    margin-left: 25px;
  }

  .text {
    margin-left: 0;
    &.greenColor {
      color: #00b366;
    }
    &.redColor {
      color: #e74a21;
    }
  }
}
