@import '../../../styles/common';

.resultsContainer {
  padding: $primaryPadding;
  background-color: $white;
  box-shadow: -4px -4px 7px rgba(0, 0, 0, 0.03), 4px 4px 7px rgba(0, 0, 0, 0.03);
  overflow: auto;
}

.block {
  margin-bottom: 20px;
}

.selectMargin {
  margin-right: 16px;
  max-width: 250px;
}

.commentBlock {
  margin: 0 0 24px 0;
  font-size: 14px;

  .commentInfo {
    margin: 0;
    font-size: 16px;
  }

  .comment {
    margin: 3px 0 0 0;
    font-style: italic;
  }
}

.marginTop {
  margin-top: 20px;
}

.tablePadding {
  table {
    td {
      padding: 16px;
    }
  }
}

.firstColumn80 {
  table {
    th:first-child {
      width: 80%;
    }
    td {
      padding: 16px;
    }
  }
}

.firstColumn40 {
  table {
    th:first-child {
      width: 40%;
    }
    td {
      padding: 16px;
    }
  }
}
