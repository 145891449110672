.comments {
  h3 {
    margin: 0 0 12px;
    font-size: 16px;
  }
}

.commentsContainer {
  max-height: 350px;
  overflow-y: scroll;
}

.commentBlock {
  margin: 0 0 24px 0;
  font-size: 14px;

  .commentInfo {
    margin: 0;
  }

  .comment {
    margin: 3px 0 0 0;
    font-style: italic;
  }
}
