.statusTextContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  .dot {
    border-radius: 100%;
    padding: 5px;
    width: 5px;
    height: 5px;

    &.pending {
      background: #00b366;
    }
    &.inProgress {
      background: #e74a21;
    }
    &.done {
      background: #c9c9c9;
    }
    &.needConfirmation {
      background: #188dd2;
    }
  }

  .text {
    margin-left: 10px;
  }
}
